@font-face { font-family: yellowtail; 
  src: url('./Yellowtail-Regular.ttf'); } 


body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  height: 100%; /* 100% Full-height */
  width: 300px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */

  display: flex;
  flex-direction: column;
  justify-content: center;
}

nav a:first {
  margin-top: 0 !important;
}

nav a {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: background-color 0.25s linear;
}

nav a.active {
  color:#61dafb;
}

nav a:not(.fade-btn:first) {
  background-color: transparent;
  
}
nav a:not(:first-child):hover {
  background-color: rgba(90, 90, 90, 0.5);
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48;
  margin-right: 10px;
}

.core {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-direction: column;
}

.alc {
  /* align-items: center; */
}

.proj {
  width: 80%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

html, body, #root { 
  width: 100%;
  background-color: #ffffff;
  /* background-color: #111; */
}

.first-div {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.second-div {
  width: 50%;
}

.App {
  /* flex-direction: row; */
}

.welcome-image {
  background: black;
  width: 100%;
  opacity: 0.5;
  max-width: 1000px;
}

.profile-name {
  color: #b81fff;
  text-shadow: 2px 2px #e7f714;
  font-family: yellowtail;
}

.social-holder {
  /* margin-top: 30px ; */
}

.start-text, .social-holder {
  
  margin-left: 20px;
  margin-right: 20px;
}

.events-holder {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.event {
  width: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;
  background: #e09cff;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-weight: bolder;
  font-size: large;
}

.cover-image {
  border-radius: 10px;
  margin: 10px;
}

.event p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.date {
}

@media (max-width: 800px) {
  .event {
    width: 100%;
  }
  .cover-image {
    border-radius: 0px;
    margin: 0px;
  }
}


.social-link {
  margin-right: 20px;
}
.first-social {
  margin-left: 0px;
}
.fa {
  font-size: 30px;
  color: black;
  width: 50px;
  text-align: center;
  text-decoration: none;
}


@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}



  @media (prefers-reduced-motion: no-preference) {
    .square-animation {
      animation: wipe-enter 1s 1;
    }
  }