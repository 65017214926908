.badge-container {
    display: flex;
    flex-direction: row;
}

.projects-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}

.project {
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    text-align: left;
    margin: auto;
    margin-bottom: 50px;

    background-color: rgb(204, 204, 204);
    border-radius: 20px;
}

svg {
    margin-right: 2em;
    margin-bottom: 2em;
}